
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.PageLayout {
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}