
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.header {
  position: relative;
  height: 70px;
  z-index: 99999;
  width: 100%;

  &__wrapper {
    border-bottom: 1px ridge rgba(245, 245, 245, 0.2);
    min-height: 70px;
  }

  &__brand {
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    flex: 1;

    @include small {
      flex: unset;
    }

    img {
      width: 50px !important;
      height: 50px !important;
    }
  }

  &__menu {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 500000;
    margin-right: 100px;

    @include small {
      position: absolute;
      top: 65px;
      right: -50px;
      flex-direction: column;
      background-color: var(--color-back);
      border: 1px solid rgba(233, 233, 233, 0.685);
      min-width: unset;
      padding: 1rem;
      border-radius: 10px;
      animation: fadeIn 0.3s ease-in-out backwards;
    }

    ul {
      display: flex;
      width: 100%;
      justify-content: space-around;
      padding: 0;
      list-style-type: none;
      margin: 0;

      @include small {
        flex-direction: column;
      }

      li {
        display: block;
        color: whitesmoke;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        min-width: fit-content;
        padding: 5px 0.5rem;
        background-image: linear-gradient(whitesmoke, whitesmoke);
        background-size: 0 1px, auto;
        background-repeat: no-repeat;
        background-position: center bottom;
        transition: all 0.2s ease-out;

        a {
          text-decoration: none;

        }

        &:hover {
          background-size: 100% 1px, auto;
        }

        @include small {
          margin-bottom: 1rem;
        }

        &:last-child {
          transform: none;
          background-size: 0;
          display: flex;
          align-items: center;
        }

        .header-menu-lang {
          border: none;
          background: none;
          display: inline-block;
          margin-right: 10px;
          padding: 0;
          cursor: pointer;

          img {
            height: 20px !important;
            width: 20px !important;
          }
        }
      }
    }
  }

  &__hamburger {
    height: 20px;
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 1rem;
    cursor: pointer;
    order: 4;

    span {
      width: 100%;
      height: 2px;
      background-color: whitesmoke;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}