
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.Search {
  position: relative;
  max-width: 26%;
  height: 100%;
  border-radius: 25px;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;



  @include small {
    padding-left: 0px;
    transition: unset;
    max-width: unset;
  }


  .Form {
    position: relative;
    height: 30px;

    label {
      display: none;
      opacity: 0;
    }
  }

  .Input {
    height: 30px;
    width: 100%;
    padding-left: 10px;
    background-color: rgba(237, 244, 252, 0.801);
    border-radius: 20px;
    color: black;
    border: 1px solid rgb(119, 119, 119);
    transform: translateX(125px);
    opacity: 0;
    transition: all 0.3s ease;


    @include small {
      padding-left: 40px;
    }
  }

  .InputOpen {
    opacity: 1;
    transform: translateX(-3px);

    &::placeholder {
      color: black;
      opacity: 0.9;
    }

    &:focus {
      outline: none;
    }
  }


  .Icon {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 30px;

    @include small {
      left: 7px;
      right: unset;
    }
  }

  .IconOpen {
    font-size: 20px;
  }

  @media only screen and (max-width: 520px) {
    .seach__input-open {
      width: 100%;
    }
  }
}