
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.square-design__a,
.square-design__b,
.square-design__c,
.square-design__d,
.square-design__e {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
}

.square-design__a {
  transform: rotate(45deg);
  background-color: var(--color-text);
  width: 150px;
  height: 150px;
}

.square-design__b {
  background-color: var(--color-text-light);
  width: 120px;
  height: 120px;

}

.square-design__c {
  background-color: black;
  width: 100px;
  height: 100px;
}

.square-design__d {
  background-color: var(--color-text-light);
  width: 80px;
  height: 80px;
}

.square-design__e {
  background-color: var(--color-text);
  width: 60px;
  height: 60px;
}