
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.Social {
    align-items: center;
    width: fit-content;
    display: flex;

    &__title {
        font-size: 14px;
        white-space: nowrap;

    }

    @include small {
        text-align: center;
    }

    &__icons {
        display: flex;
        align-items: center;

        img {
            height: 50px;
            width: 50px;
            cursor: pointer;
        }

        @include small {
            justify-content: center;
        }
    }
}