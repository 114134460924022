
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.container {
    margin: auto;
    padding: 0 2rem;

    @include Xsmall {
        max-width: 540px;
    }

    @include small {
        padding: 0 10px;
        max-width: 720px;
    }

    @include medium {
        padding: 0 1rem;
        max-width: 960px;
    }

    @include large {
        max-width: 1140px;
    }

    @include xlUp {
        max-width: 1320px;
      }
}