
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.footer {
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 0.9) 54%,
      rgba(255, 255, 255, 0.8) 81%,
      rgba(255, 255, 255, 0.6) 100%);
  z-index: 5000;
  position: relative;
  padding-top: 1rem;

  &__social {
    @include small {
      align-items: center !important;
      margin-bottom: 2rem;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    width: 100%;

    @include small {
      grid-template-columns: 1fr;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @include small {
      width: 100%;
    }

    &-add,
    a {
      background: transparent;
      text-align: start;
      font-weight: 600;
      cursor: pointer;
      border: none;
      font-size: 14px;
      margin-bottom: 1rem;
      text-decoration: none;
      color: black;

      @include small {
        text-align: center;
      }
    }

    &-contact {
      margin-bottom: 1rem;
    }
  }

  &__logo {
    text-align: end;

    @include small {
      width: 100%;
      text-align: center;
    }

    img {
      width: 100px;
      height: 100px;
    }

    @include small {
      padding-right: 0;
      margin: 15px 0;
    }
  }

  &__copyright-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    font-size: 12px;
    background: transparent;

    @include small {
      width: 200px;
      margin: 0 auto;
      flex-direction: column;
    }
  }

  &__copyright {
    text-align: center;
    font-weight: 400;
  }

  &__copyright-btn {
    margin-left: 8px;
    color: var(--backColor);
  }
}