
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.contact {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background-color: var(--color-text-light);
  padding: 2rem 1rem;
  position: relative;
  overflow: hidden;
  padding-bottom: 4rem;
  z-index: 2000;
  border-radius: 6px;
  @include fadeIn;

  @include medium {
    margin-top: 2rem;
  }


  &__title {
    border-bottom: 2px solid var(--color-text);
    margin-bottom: 1rem;
    font-size: 28px;
    color: var(--color-text);
    text-align: center;
    width: fit-content;

    @include small {
      font-size: 25px;
    }
  }

  &__subtitle {
    width: 65%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;

    @include Xsmall {
      width: 85%;
    }
  }

  &__text {
    padding-top: 10px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.8px;

    @include Xsmall {
      width: 85%;
    }
  }

  &__form {
    width: 100%;
  }

  &__input {
    height: 35px;
    padding-left: 8px;
  }

  &__input,
  &__text-area {
    width: 40%;
    margin-top: 1.5rem;
    border: 1px solid var(--color-text);
    background-color: #2384a22f;

    &:focus {
      outline: 2px solid var(--color-text);
    }

    @include small {
      width: 80%;
    }

    @include Xsmall {
      width: 100%;
    }
  }

  &__text-area {
    resize: vertical;
    min-height: 180px;
    padding: 8px 0 0 8px;
    font-family: Verdana, Geneva, sans-serif;
    font-display: swap;
  }

  &__limit {
    position: relative;
    bottom: 1.2rem;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }

  &__btn {
    position: relative;
    margin-top: 1rem;
  }

  &__thank {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    bottom: 8px;
  }

  &__error {
    font-size: 12px;
    line-height: 0;
    position: relative;
    top: 8px;
    color: red;
  }

  .design__1,
  .design__2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .design__1 {
    left: -80px;

    @include small {
      bottom: -123px;
      top: unset;
      transform: unset;
    }
  }

  .design__2 {
    right: -80px;

    @include small {
      bottom: -123px;
      top: unset;
      transform: unset;
    }
  }
}

.contact__form,
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}