
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.game-card {
  display: grid;
  grid-template-columns: 120px auto;
  width: 100%;
  min-height: 130px;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid transparent;
  @include fadeIn;

  &:hover {
    box-shadow: #07344957 20px -40px 40px -28px inset;
  }

  @include small {
    width: 100%;
  }

  &__img {
    min-width: 120px !important;
    width: 120px !important;
    height: 120px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black !important;
    border-radius: 6px;
    object-fit: cover;
  }

  &__description {
    padding: 5px;
    text-align: justify;
    word-break: keep-all;
  }

  &__title {
    color: var(--color-text);
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 12px;
    color: black;

    @include Xsmall {
      line-height: 12px;
    }
  }
}