
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.Button {
  background-color: var(--color-text);
  color: white;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  border: 1px solid transparent;
  margin: 0 auto;
  border-radius: 4px;

  &:hover {
    background-color: white;
    color: var(--color-text);
    border: 1px solid var(--color-text);
  }
}