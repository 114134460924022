
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.contact-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(12, 0, 42, 0.85);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: 5000000;

    .contact {
        margin-bottom: 0;
        width: 100%;
        padding: 1.5rem;
        top: 0;
    }

    &__icon {
        position: absolute;
        cursor: pointer;
        right: 15px;
        top: 15px;
        z-index: 99999;

        svg {
            color: var(--color-back);
        }
    }

    &__formu {
        position: relative;
        max-width: 700px;
        width: 100% !important;
        background-color: whitesmoke;
        border-radius: 8px;

        @include Xsmall {
            max-width: 500px;
        }
    }

}